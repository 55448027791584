html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.app-sidenav-header-background-wrapper,
.app-sidenav-header-background {
  background-color: #006e28;
}

.mat-nav-list .mat-list-item.selected:not(.mat-list-item-disabled):hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-list-base .mat-list-item:not(.selected) > .mat-list-item-content:hover {
  background: rgba(0, 0, 0, 0.04);
}

.mat-nav-list a.selected:focus {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-sidenav.sticky > .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mat-sidenav.sticky .app-sidenav-content-wrapper {
  overflow-y: auto;
}

.mat-sidenav ifc-app-logo > a > img {
  filter: brightness(0) invert(1);
}

ifbp-advisor-widget .mat-card-avatar {
  border-radius: 50%;
}

.app-bar-container {
  max-height: 108px;
  height: auto;

  &.padding {
    padding: 4px 12px 0;
  }

  &.padding-xs {
    padding: 4px 12px 0;
  }
}

.dashboard-app-bar .app-bar-container {
  &.padding {
    padding: 12px;
  }

  &.padding-xs {
    padding: 12px;
  }
}

.app-sidenav-header #ifc-co-branding-logo {
  margin-left: 10px !important;
}

.ifc-app-bar-actions button {
  border-radius: 20px;
  width: 100px;
}

.default-app-page-content {
  background-color: white;
}

.uppy-dialog mat-dialog-container {
  padding:0;
}
